@font-face {
  font-family: Cano;
  src: url("../assets/fonts/Cano.otf") format("opentype");
}

@font-face {
  font-family: "Neo Sans";
  src: url("../assets/fonts/NeoSansStd-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Open Sans";
  src: url("../assets/fonts/OpenSans-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "Open Sans Italic";
  src: url("../assets/fonts/OpenSans-SemiBoldItalic.ttf") format("opentype");
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
